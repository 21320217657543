.logo {
	left: 0;
	width: 70px;
	object-fit: contain;
	top: 10px;
	cursor: pointer;
	padding-left: 20px;
}

.header_links {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	list-style-type: none;
}

.header_link {
	color: #10b981 !important;
	text-decoration: none;
	font-size: x-large;
	padding: 10px;
}

.header_link:hover {
	text-decoration: none;
	color: #26da9e !important;
}

#root > div > nav > div {
	margin: auto;
}
