.predict{
    background: rgb(16, 185, 129, 0.1);
}

.sell_item{
    background: rgb(16, 185, 129, 0.1);
	border-color: rgb(107,114,128);
}

.sell_item:hover{
    background: rgb(16, 185, 129);
}