.feedback{
  background: rgb(16, 185, 129, 0.1);
}

.bg-color {
	background: rgb(16, 185, 129, 0.1);
	border-color: rgb(107,114,128);
}

.bg-color:hover {
	background: rgb(16, 185, 129);
}