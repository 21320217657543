#root > div > div {
margin-top: 85px;
}

.crop-card{
    margin: auto;
}

.bid_info{
    padding: 30px;
    background-color: white;
    max-width: 400px;
    margin: auto;
    border: 1px solid;
    border-radius: 10px
}

.crop-inside{
    display: flex;
    justify-content: space-between;
}

.bg-color {
	background: rgb(16, 185, 129, 0.1);
	border-color: rgb(107,114,128);
}

.bg-color:hover {
	background: rgb(16, 185, 129);
}

@media screen and (max-width: 480px){
    .crop-inside{
    display: block;
    }
}
