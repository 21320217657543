.crop {
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.crop :hover {
  cursor: pointer;
}

.image {
  height: 150px;
}
